import { Environment } from './environment.interface';

export const industryEnvironment: Environment = {
  production: false,
  api: '/industry-risk/api/v1/',
  frontendApi: '/industry-risk/fe-api/v1/',
  environment: 'staging',
  hotjarTrackingCode: null,
  googleTagManagerId: 'GTM-NDCBRRP',
  csrfToken: 'csrftoken_nonprod',
};
