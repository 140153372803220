<div class="legend-container">
  <div class="row pb-3">
    <div class="col-12">
      {{ indexExplanation }}
    </div>
  </div>
  <div class="row pb-3 no-gutters key-container">
    <div class="col-3">
      <span class="text-center key colour-coded extreme">0.00-2.50</span>
      <span>Extreme</span>
      <div class="gradient-bar extreme"></div>
    </div>
    <div class="col-3">
      <span class="text-center key colour-coded high">2.51-5.00</span>
      <span>High</span>
      <div class="gradient-bar high"></div>
    </div>
    <div class="col-3">
      <span class="text-center key colour-coded medium">5.01-7.50</span>
      <span>Medium</span>
      <div class="gradient-bar medium"></div>
    </div>
    <div class="col-3">
      <span class="text-center key colour-coded low">7.51-10.00</span>
      <span>Low</span>
      <div class="gradient-bar low"></div>
    </div>
  </div>
  <div class="row no-gutters" *ngIf="!lite">
    <div class="col-3">
        <grid-ui-icon class="subnational-icon" name="subnational-circle"></grid-ui-icon>
        <span>Subnational</span>
    </div>
  </div>
</div>
<div class="legend-footer-container" *ngIf="!lite">
  <div class="row no-gutters key-container">
    <div class="col-3">
      <span><b>Verisk Maplecroft's Language of Probability:</b></span>
    </div>
    <div class="col-2">
      <span><b>Expected: </b>75% to &lt; 100%</span>
    </div>
    <div class="col-2">
      <span><b>Likely: </b>&gt; 50% to &lt; 75%</span>
    </div>
    <div class="col-2">
      <span><b>Unlikely: </b>25% to &lt; 50%</span>
    </div>
    <div class="col-2">
      <span><b>Remote: </b>&gt; 0% to &lt; 25%</span>
    </div>
    <div class="col-1">
    </div>
  </div>
</div>
