import { Environment } from './models';

export const gridEnvironment: Environment = {
  environment: 'staging',
  csrfToken: 'csrftoken_nonprod',
  production: true,
  test: false,
  appConfig: {
    minIndexEdition: '2017-Q1',
    agGrid: {
      useUnsortIcon: true,
      suppressMenuHide: true
    },
    locationTables: {
      filterMaxOptions: 500
    },
    jiraTicketCollector: {
      enableForAllUsers: true,
      enableForAccountUuids: [],
       
      collectorScriptUrl: 'https://maplecroft-jira.atlassian.net/s/d41d8cd98f00b204e9800998ecf8427e-T/osugvy/b/13/a44af77267a987a660377e5c46e0fb64/_/download/batch/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector.js?locale=en-UK&collectorId=0e5fdad9'
    },
    sentryDsn: 'https://67ac2599052245cbaaa7bf4032a2effc@sentry.maplecroft.com/6'
  },
  paths: {
    img: '/assets/img',
    icons: '/assets/img/icons',
  },
  googleTagManagerId: 'GTM-WKT7W45',
  changePasswordUrl: null,
  blankMapTileUrl: 'https://tiles.staging.maplecroft.com/map-resources/tiles/rasters-2012.blank_raster/{z}/{x}/{y}.png',
  industryFrontendApi: '/industry-risk/fe-api/v1/',
  mapsBackendBaseURL: 'https://maps.staging.maplecroft.com/maps', // Assumes CloudFront.
  mapsOpenStreetMapVectorTilesetName: 'grid-analyse_v0.2.13'
};
